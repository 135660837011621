<script>
    import "../app.css";
	  import Nav from '$lib/nav.svelte';
    import { Toaster, toast } from 'svelte-sonner'
    import { onMount } from 'svelte';
    import { apiData } from '$lib/store';
    import pollAPI from "$lib/poll";
        // check the body class for background color
        // if it is not the right color, change it
        // if (document.body.classList.contains("bg-slate-400")) {

    onMount( () => {
        // apiData.set(response)
        pollAPI()

    })
</script>
  
<Toaster richColors />
<!-- <Nav /> -->
  <slot />

<div class="cf-turnstile" data-sitekey="0x4AAAAAAAPWPaD7OVVK0SB4" data-callback="javascriptCallback"></div>

<style>
	:global(body) {
		background-color: #E5D4B8;
		opacity: 1;
		background-position: 0 0,35px 35px;
	}
</style>